import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IntakeSurvey,
  IntakeSurveyFamilyInfo,
  IntakeSurveyInitialInfo,
  IntakeSurveyInitialInfoPayload,
} from '@features/intake-survey/models/intake-survey.models';
import { ActionsUtils } from '@store/utils';
import { ApiError } from '@core/models/http.models';

const PREFIX = '[Intake Survey API]';

export const IntakeSurveyRootActions = createActionGroup({
  source: `${PREFIX} Root`,
  events: {
    reset: emptyProps(),
  },
});

export const LoadFullInfoActions = createActionGroup({
  source: `${PREFIX} Load Info`,
  events: ActionsUtils.makeEvents<IntakeSurvey>(),
});

export const PostInitialInfoActions = createActionGroup({
  source: `${PREFIX} Post Initial Info`,
  events: {
    init: props<{ payload: IntakeSurveyInitialInfoPayload }>(),
    store: props<{ payload: IntakeSurveyInitialInfo }>(),
    success: props<{ response: IntakeSurvey }>(),
    failure: props<{ error: ApiError }>(),
  },
});

export const PostFamilyInfoActions = createActionGroup({
  source: `${PREFIX} Post Family Info`,
  events: ActionsUtils.makeEventsWithPayload<IntakeSurveyFamilyInfo, IntakeSurvey>(),
});
