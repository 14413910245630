import { EntityStateBase } from '@store/models';
import { IntakeSurveyFamilyInfo, IntakeSurveyInitialInfo } from '@features/intake-survey/models/intake-survey.models';

export interface IntakeSurveyState extends EntityStateBase {
  initialInfo: IntakeSurveyInitialInfo | null;
  familyInfo: IntakeSurveyFamilyInfo | null;
}

export const createInitialState = (): IntakeSurveyState => {
  return {
    isLoaded: false,
    isFetching: false,
    isProcessing: false,
    initialInfo: null,
    familyInfo: null,
  };
};
