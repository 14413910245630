import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INTAKE_SURVEY_FEATURE_KEY } from './intake-survey.reducer';
import { IntakeSurveyState } from './intake-survey.state';

const selectState = createFeatureSelector<IntakeSurveyState>(INTAKE_SURVEY_FEATURE_KEY);

const isFetching = createSelector(selectState, (state) => state.isFetching);
const isProcessing = createSelector(selectState, (state) => state.isProcessing);

const initialInfo = createSelector(selectState, (state) => state.initialInfo);
const familyInfo = createSelector(selectState, (state) => state.familyInfo);

export const IntakeSurveySelectors = {
  isFetching,
  isProcessing,
  initialInfo,
  familyInfo,
};
