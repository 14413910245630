import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntakeSurveyState } from './intake-survey.state';
import { Store } from '@ngrx/store';
import { exhaustMap, switchMap } from 'rxjs';

import { LoadFullInfoActions, PostFamilyInfoActions, PostInitialInfoActions } from './intake-survey.actions';

import { EffectsBase } from '@store/models';
import { IntakeSurveyApi } from '@features/intake-survey/services/intake-survey.api';

@Injectable()
export class IntakeSurveyEffects extends EffectsBase<IntakeSurveyState> {
  loadInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadFullInfoActions.init),
      switchMap(() => this.api.getFullInfo().pipe(this.handleResponse(LoadFullInfoActions)))
    )
  );

  postInitialInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostInitialInfoActions.init),
      exhaustMap(({ payload }) => this.api.postInitialInfo(payload).pipe(this.handleResponse(PostInitialInfoActions)))
    )
  );

  postFamilyInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostFamilyInfoActions.init),
      exhaustMap(({ payload }) => this.api.postFamilyInfo(payload).pipe(this.handleResponse(PostFamilyInfoActions)))
    )
  );

  constructor(
    public override store: Store,
    private actions$: Actions,
    private api: IntakeSurveyApi
  ) {
    super(store);
  }
}
