import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import { createInitialState, IntakeSurveyState } from './intake-survey.state';
import {
  IntakeSurveyRootActions,
  LoadFullInfoActions,
  PostFamilyInfoActions,
  PostInitialInfoActions,
} from './intake-survey.actions';
import { AuthActions } from '@features/auth/store';
import { ReducerUtils } from '@store/utils';

export const INTAKE_SURVEY_FEATURE_KEY: string = 'intake-survey';

export const initialState: IntakeSurveyState = createInitialState();

type State = IntakeSurveyState;

export const intakeSurveyReducer: ActionReducer<IntakeSurveyState, Action> = createReducer(
  initialState,

  // ROOT reset
  on(AuthActions.logout, (): State => initialState),
  on(IntakeSurveyRootActions.reset, (): State => initialState),

  // LOAD Full Info
  on(LoadFullInfoActions.init, ReducerUtils.handleLoadInit()),
  on(
    LoadFullInfoActions.success,
    (state, { response }): State => ({
      ...state,
      ...response,
      isLoaded: true,
      isFetching: false,
    })
  ),
  on(LoadFullInfoActions.failure, ReducerUtils.handleLoadFailure()),

  // POST Initial Info
  on(PostInitialInfoActions.store, (state, { payload }): State => ({ ...state, initialInfo: payload })),
  on(PostInitialInfoActions.init, ReducerUtils.handleProcessInit()),
  on(
    PostInitialInfoActions.success,
    (state, { response }): State => ({
      ...state,
      isProcessing: false,
      initialInfo: response.initialInfo,
    })
  ),
  on(PostInitialInfoActions.failure, ReducerUtils.handleProcessFailure()),

  // POST Family Info
  on(PostFamilyInfoActions.store, (state, { payload }): State => ({ ...state, familyInfo: payload })),
  on(PostFamilyInfoActions.init, ReducerUtils.handleProcessInit()),
  on(
    PostFamilyInfoActions.success,
    (state, { response }): State => ({
      ...state,
      isProcessing: false,
      familyInfo: response.familyInfo,
    })
  ),
  on(PostFamilyInfoActions.failure, ReducerUtils.handleProcessFailure())
);
