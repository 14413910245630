import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpApi } from '@core/services/api/http.api';
import {
  IntakeSurvey,
  IntakeSurveyFamilyInfo,
  IntakeSurveyInitialInfoPayload,
} from '@features/intake-survey/models/intake-survey.models';
import { Parent } from '@core/models/entities/parent.models';
import { FamilyUtils } from '@features/families/utils/family.utils';

const feature = 'parent';

@Injectable({ providedIn: 'root' })
export class IntakeSurveyApi {
  constructor(private readonly httpApi: HttpApi) {}

  getFullInfo(): Observable<IntakeSurvey> {
    return this.httpApi.get(`${feature}/my`).pipe(this.mapResponse());
  }

  postInitialInfo(payload: IntakeSurveyInitialInfoPayload): Observable<IntakeSurvey> {
    return this.httpApi.patch(`${feature}/initial`, payload).pipe(this.mapResponse());
  }

  postFamilyInfo(payload: IntakeSurveyFamilyInfo): Observable<IntakeSurvey> {
    return this.httpApi.put(`${feature}/family-info`, payload).pipe(this.mapResponse());
  }

  private mapResponse = () => {
    return map<unknown, IntakeSurvey>((data) => {
      const user = data as Parent;

      console.log(user);

      return <IntakeSurvey>{
        initialInfo: FamilyUtils.mapParentToFamilyUpdateForm(user),
        familyInfo: user.info,
      };
    });
  };
}
